import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let bigParallax = document.getElementsByClassName('big-parallax');
let bg1Parallax = document.getElementsByClassName('bg1-parallax');
let bg2Parallax = document.getElementsByClassName('bg2-parallax');
let animateType3LeftImage = document.getElementsByClassName('animate-type3-left-image');
let animateType3RightImage = document.getElementsByClassName('animate-type3-right-image');
let animatePinDrop = document.getElementsByClassName('animate-pin-drop');
let animateLeftImage = document.getElementsByClassName('animate-left-image');
let animateRightImage = document.getElementsByClassName('animate-right-image');
let animateDownArrow = document.getElementsByClassName('animate-down-arrow');

let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

if(animateType3LeftImage.length){
    gsap.set(".animate-type3-left-image img", {
        x: '-75%'
    });
}

if(animateType3RightImage.length){
    gsap.set(".animate-type3-right-image img", {
        x: '50%'
    });
}

if(animateLeftImage.length){
    gsap.set(".animate-left-image img", {
        x: '-100%'
    });
}

if(animateRightImage.length){
    gsap.set(".animate-right-image img", {
        x: '100%',
        y: '50%',
    });
}


if(window.innerWidth > 1024){
    // Big Image Parallax
    if(bigParallax.length){

        for (var i = bigParallax.length - 1; i >= 0; i--) {
            let parent = bigParallax[i];

            gsap.to(bigParallax[i], {
                yPercent: -100,
                scrollTrigger:  {
                    trigger: bigParallax[i],
                    scrub: true
                },
                ease: "none",
            });
        };
    }

    // Type 1 Image Parallax
    if(bg1Parallax.length){
        for (var i = bg1Parallax.length - 1; i >= 0; i--) {

            let parent = bg1Parallax[i].parentNode.parentNode.parentNode;
            let toTop = 0 -  (bg1Parallax[i].offsetHeight - parent.offsetHeight);

            var tl = gsap.timeline({
                scrollTrigger:  {
                    trigger: parent,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true,
                    invalidateOnRefresh: true
                }
            });

            tl.fromTo(bg1Parallax[i],
                {
                    top: '0',
                    ease: "none",
                },
                {
                    top: toTop,
                    ease: "none",
                }
            );
        };
    }

    // Type 2 Image Parallax
    if(bg2Parallax.length){
        for (var i = bg2Parallax.length - 1; i >= 0; i--) {
            let parent = bg2Parallax[i].parentNode.parentNode.parentNode;
            let toTop = 0 -  (bg2Parallax[i].offsetHeight - parent.offsetHeight);

            var tl = gsap.timeline({
                scrollTrigger:  {
                    trigger: parent,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true,
                    invalidateOnRefresh: true
                }
            });

            tl.fromTo(bg2Parallax[i],
                {
                    top: '0',
                    ease: "none",
                },
                {
                    top: toTop,
                    ease: "none",
                }
            );
        };
    }

    // Type 3 Image Parallax: Left Image
    if(animateType3LeftImage.length){
        gsap.from(animateType3LeftImage, {
            yPercent: -50,
            scrollTrigger: {
                trigger: ".module-content_type_3",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                markers: false,
                id: "left"
            },
            ease: "none",
        });

        gsap.from(".animate-type3-left-image img", {
            rotation: 10,
            scrollTrigger: {
                trigger: ".module-content_type_3",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            },
            ease: "none",
        });
    }

    // Type 3 Image Parallax: Right Image
    if(animateType3RightImage.length){
        gsap.from(animateType3RightImage, {
            yPercent: 40,
            scrollTrigger: {
                trigger: ".module-content_type_3",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                markers: false,
                id: "right"
            },
            ease: "none",
        });

        gsap.from(".animate-type3-right-image img", {
            rotation: 5,
            scrollTrigger: {
                trigger: ".module-content_type_3",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            },
            ease: "none",
        });
    }

    // Near You Pin Drop
    if(animatePinDrop.length){
        gsap.from(animatePinDrop, {
            yPercent: -60,
            opacity: 0,
            duration: 1,
            scrollTrigger: {
                trigger: ".animate-pin-drop",
                start: "-300% center",
                end: "-300% 100px",
                scrub: false,
                markers: false,
                id: "pin"
            },
            ease: "bounce.out",
        });
    }

    // Upcoming Shows; Left Image
    if(animateLeftImage.length){
        gsap.to(animateLeftImage, {
            yPercent: -20,
            scrollTrigger: {
                trigger: ".module-upcoming_shows",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            },
            ease: "none",
        });

        gsap.from(".animate-left-image img", {
            rotation: -20,
            scrollTrigger: {
                trigger: ".module-upcoming_shows",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            },
            ease: "none",
        });
    }

    // Upcoming Shows; Right Image
    if(animateRightImage.length){
        gsap.from(animateRightImage, {
            yPercent: 40,
            scrollTrigger: {
                trigger: ".module-upcoming_shows",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            },
            ease: "none",
        });

        gsap.from(".animate-right-image img", {
            rotation: 20,
            scrollTrigger: {
                trigger: ".module-upcoming_shows",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
            },
            ease: "none",
        });
    }

    // Upcoming Shows; Down Arrow
    if(animateDownArrow.length){
        gsap.from(animateDownArrow, {
            yPercent: -70,
            opacity: 0,
            scrollTrigger: {
                trigger: ".module-upcoming_shows",
                start: "top 20%",
                end: "20% top",
                scrub: true,
            },
            ease: "none",
        });
    }
}