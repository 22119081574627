let fetchURL = "/wp-admin/admin-ajax.php?action=find_closest_event";

// console.log('Geolocator Fetch:', fetchURL);

fetch( fetchURL ).then(function (response) {
    if(response.ok){
        return response.json();
        // console.log('GEO 1: find_closest_event');
    }
    else{
        // console.log('GEO X:', response);
        return Promise.reject(response);
    }
}).then(function (data) {

    let resultsWrapper = document.querySelectorAll('.geolocator-results');
    let noResultsWrapper = document.querySelectorAll('.geolocator-noresults');



    // console.log('GEO 2: ', data);

    if(typeof data === "object" && typeof data.event.city !== "undefined"){
        let type = data.event.type;
        let city = data.event.city;
        let dates = data.event.dates;
        let tix = data.event.tickets_link;

        // console.log('GEO 3: ', type, city, dates, tix);

        for (var i = resultsWrapper.length - 1; i >= 0; i--) {

            let resultsHTML = resultsWrapper[i];
            let noResultsHTML = noResultsWrapper[i];
            let dataset = resultsHTML.dataset.type;
            // console.table(
            //     'ID: ', i,
            //     '\nType: ', type,
            //     '\nResults: ', resultsHTML,
            //     '\nNo Results: ', noResultsHTML,
            //     '\nData: ', dataset
            // );

            if (typeof dataset !== "undefined") {
                resultsHTML.classList.remove('hidden');
                noResultsHTML.classList.add('hidden');

                let domHeader = resultsHTML.querySelectorAll('.geolocator-header');
                let domCity = resultsHTML.querySelectorAll('.geolocator-city');
                let domDate = resultsHTML.querySelectorAll('.geolocator-date');
                let domTix  = resultsHTML.querySelectorAll('.geolocator-tickets');

                if(dataset === "nearby_only"){
                    //  console.log('First One: ', i, city, dates, tix, type);
                    //  console.log(
                    //     resultsHTML,
                    //     domCity[0],
                    //     domDate[0],
                    //     domHeader[0]
                    // );

                    if(type == "upcoming"){
                        domHeader[0].textContent = "Next Show";
                    }
                    else{
                        domHeader[0].textContent = "Near You";
                    }

                    domCity[0].textContent = city;
                    domDate[0].textContent = dates;
                    domTix[0].setAttribute("href", tix);
                }
            }
            else{
                resultsHTML.classList.add('hidden');
                noResultsHTML.classList.remove('hidden');

                let domHeader = noResultsHTML.querySelectorAll('.geolocator-header');
                let domCity = noResultsHTML.querySelectorAll('.geolocator-city');
                let domDate = noResultsHTML.querySelectorAll('.geolocator-date');
                let domTix  = noResultsHTML.querySelectorAll('.geolocator-tickets');


                if(type == "upcoming"){
                    // console.log('ME: ', i, city, dates, tix);
                    // console.log(
                    //     domCity[0],
                    //     domDate[0],
                    //     domHeader[0]
                    // );
                    domHeader[0].textContent = "Next Show";
                }
                else{
                    domHeader[0].textContent = "Near You";
                }

                domCity[0].textContent = city;
                domDate[0].textContent = dates;
                domTix[0].setAttribute("href", tix);
            }

        };
    }
    else{
        for (var i = resultsWrapper.length - 1; i >= 0; i--) {
            if(resultsWrapper[i].length){
                resultsWrapper[i].classList.add('hidden');
            }
            if(resultsWrapper[i].length){
                noResultsWrapper[i].classList.remove('hidden');
            }
        };
    }

}).catch(function (err) {
    console.warn('Something went wrong.', err);
});